.registration {
  width: 85vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 15vh;
  left: 10vw;
  z-index: 1999;
}
.registration .reg-container {
  width: 500px !important;
  height: 70vh;
  background-color: #fefefec3;
  border-radius: 10px;
  box-shadow: 0px 0px 20px #000000c5;
  padding: 20px;
  backdrop-filter: blur(8px);
}
.registration .reg-container .reg-div {
  height: inherit;
  display: flex;
  overflow: hidden;
}
.registration .reg-container .reg-div p {
  margin-left: -60px;
  width: inherit;
}

.registration .reg-container .reg-div .registration-div {
  height: 92%;
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  align-items: center !important;
  position: relative;
}
.registration .reg-container .reg-div .registration-div .back-arrow {
  color: #2b2b2b;
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
  padding: 5px;
  box-shadow: 0px 0px 10px rgb(185, 185, 185);
}
.registration .reg-container .reg-div .registration-div .back-arrow:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 14px rgb(92, 91, 91);
}
.registration .reg-container .sub-header-reg {
  font-weight: 700;
  font-size: 24px;
  /* width: 80%; */
  text-align: center;
  color: #2b2b2b;
}
.registration .reg-container p {
  text-align: center;
  color: #1b1b1b;
}
.registration .reg-container p a {
  color: #da0000;
  cursor: pointer;
}
.registration .reg-container .reg-logo {
  width: 20vw;
}
.registration .reg-container .google-btn {
  overflow: hidden !important;
  background-color: #00b7b2 !important;
}

@media (max-width: 782px) {
  .registration .reg-container {
    width: 400px !important;
    min-width: 300px !important;
  }
  .registration .reg-container .reg-div .reg-types .reg-type {
    margin-left: 0px;
  }
}
@media (max-width: 578px) {
  .registration .reg-container {
    width: 250px !important;
  }
  .registration .reg-container .sub-header-reg {
    font-weight: 700;
    font-size: 22px;
  }
  .registration .reg-container .google-btn {
    width: 200px !important;
    font-size: 12px !important;
  }
}
@media (max-width: 478px) {
  .registration .reg-container .reg-div .reg-types {
    margin-left: -118%;
    width: 260px !important;
    min-width: 260px !important;
  }
  .registration .reg-container .reg-div .registration-div {
    width: 260px;
    padding: 20px;
  }
  .registration .reg-container .reg-logo {
    width: 180px;
  }
  .registration .reg-container .reg-div .reg-types {
    margin-left: -110%;
  }
}

@media (max-width: 358px) {
  .registration .reg-container {
    width: 180px !important;
  }
  .registration .reg-container .sub-header-reg {
    font-weight: 700;
    font-size: 22px;
  }
  .registration .reg-container .google-btn {
    /* border-radius: 10px !important; */
    width: 100px !important;
    font-size: 8px !important;
  }
}
