.pagetitle {
  margin-bottom: 10px;
  text-align: left;
}

.pagetitle h1 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 600;
  color: #2b2b2b;
}

.breadcrumb {
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  color: #2b2b2b;
  font-weight: 600;
}

.breadcrumb a {
  color: #2b2b2b;
  transition: 0.3s;
}

.breadcrumb a:hover {
  color: #2b2b2ba6;
}

.breadcrumb .breadcrumb-item::before {
  color: #2b2b2b;
}

.breadcrumb .active {
  color: #2b2b2ba6;

  font-weight: 600;
}
