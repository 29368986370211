.sales-container {
  padding: 1rem;
  max-width: 100%;
}

.card {
  border: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  overflow: hidden;
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.filter {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.table {
  width: 100%;
  margin-top: 1rem;
}

.table-responsive {
  overflow-x: auto;
}

.preview-img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
}

.badge {
  padding: 0.4rem 0.6rem;
  font-size: 0.9rem;
  border-radius: 12px;
}

.badge-delivered {
  background-color: #28a745;
  color: #fff;
}

.badge-in-progress {
  background-color: #ffc107;
  color: #212529;
}

.badge-returned {
  background-color: #dc3545;
  color: #fff;
}

.form-select {
  width: auto;
  padding: 0.2rem 0.5rem;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .table th,
  .table td {
    font-size: 0.9rem;
    white-space: nowrap;
  }

  .sales-container {
    padding: 0.5rem;
  }

  .preview-img {
    width: 50px;
    height: 35px;
  }
}
