.edit-add-product-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0000006c;
  backdrop-filter: blur(6px);
  z-index: 1999;
  overflow-y: scroll;
  padding-top: 20vh;
  padding-bottom: 5vh;
}
