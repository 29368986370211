.add-product {
  margin-bottom: 10vh;
}
.add-product .details-grid {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 60vw;
}
.add-product .images {
  overflow-x: scroll;
  scrollbar-width: none;
  width: 100%;
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.131);
  border-radius: 10px;
  padding-top: 3vh;
}
.add-product .images::-webkit-scrollbar {
  display: none;
}

.add-product .image-div {
  height: 240px;
  width: 240px;
  min-height: 240px;
  min-width: 240px;
  border-radius: 10px;
  position: relative;
  margin-bottom: 3vh;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 10px;
  margin-right: 10px;
  backdrop-filter: blur(8px);
  box-shadow: 5px 5px 10px #7d7d7d;
  overflow: hidden;
}

.add-product .image-div.main {
  height: 300px;
  width: 300px;
}
.add-product label {
  position: absolute;
  bottom: 5%;
  left: 5%;
  width: 30%;
  height: 15%;
  background-color: #01909339;
  border-bottom-left-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  transition: 0.4s;
  border-radius: 10px;
  backdrop-filter: blur(8px);
}
.add-product label:hover {
  background-color: #019093;
  transform: scale(1.2);
}
.add-product .add-photo-icon {
  color: white;
  font-size: 28px;
  transition: 0.4s;
}

.add-product input {
  box-sizing: border-box;
  border: 1px solid #ddd;
  padding: 5px;
  resize: vertical; /* Allow vertical resizing */
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #444444;
  text-align: inherit;
  outline: none !important;
  display: block; /* Ensures it doesn't stretch horizontally */
}
.add-product .delete-image-btn {
  position: absolute;
  bottom: 5%;
  right: 5%;
  width: 30%;
  height: 15%;
  background-color: #da000039;
  border: none;
  border-radius: 10px;
  backdrop-filter: blur(8px);
  transition: 0.4s;
}
.add-product .delete-image-btn .delete-photo-icon {
  color: white;
  font-size: 32px;
}
.add-product .delete-image-btn:hover {
  background-color: #da0000;
  transform: scale(1.2);
}
.add-product .add-more-photos {
  width: 60%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}
.add-product .add-more-photos label {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #019093;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.4s;
}
.add-product .add-more-photos label:hover {
  transform: scale(1.1);
}
.add-product .add-more-photos .add-more-photo-icon {
  font-size: 42px;
  color: white;
}
.detail-row {
  display: flex;
  align-items: stretch; /* Ensures both title and value align in height */
  gap: 10px;
}

.detail-title {
  flex: 1; /* Ensures titles and values take up equal space */
  font-weight: bold;
  text-align: left;
  background-color: #c2c4c7;
  padding: 10px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
}

.detail-value {
  flex: 2;
  text-align: left;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 2vh;
}
