.section-subheader {
  /* margin-bottom: 2vh; */
  width: 50vw;
  z-index: 12;
}
.section-subheader h2 {
  color: #da0000;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.section-subheader h3 {
  font-size: 32px;
  font-weight: 700;
  text-transform: capitalize;
  font-family: "Galano Grotesque Alt", sans-serif;
  color: #071c2e;
}
@media (max-width: 868px) {
  .section-subheader {
    width: 90vw;
  }
  .section-subheader h3 {
    font-size: 22px;
  }
}
