/* Info Cards */
.info-card {
  padding-bottom: 10px;
  /* height: 180px; */
}

.info-card h6 {
  font-size: 22px !important;
  color: #2b2b2b;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.info-card .card-icon {
  font-size: 24px;
  line-height: 0;
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  flex-grow: 0;
}

.sales-card .card-icon {
  color: #4154f1;
  background: #f6f6fe;
}

.revenue-card .card-icon {
  color: #2eca6a;
  background: #e0f8e9;
}

.customers-card .card-icon {
  color: #ff771d;
  background: #ffecdf;
}
.user-card .card-icon {
  color: #000;
  background: #def60383;
}
/* @media (max-width: 992px) {
  .info-card {
    height: auto;
  }
} */
