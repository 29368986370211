.product-card {
  text-decoration: none;
  color: #1b1b1b;
  text-align: center;
  margin: 3px;
  margin-bottom: 3vh;
  transition: 0.4s;
  /* background: radial-gradient(#ffffff, #01909310); */
  /* background-color: white; */
  border-radius: 10px;
  box-shadow: 0px 5px 5px rgba(96, 96, 96, 0.263);
  position: relative;
  background-color: rgba(255, 255, 255, 0.225);
  backdrop-filter: blur(8px);
}
.product-card:hover {
  box-shadow: 0px 10px 10px rgba(96, 96, 96, 0.683);
  border-radius: 20px;
  transform: translateY(-5%);
}
.product-card .add-to-cart-icon-view {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f3f3f3ce;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px gray;
  transition: 0.4s;
  display: flex;
  z-index: 1234;
  justify-content: center;
  align-items: center;
}
.product-card:hover .add-to-cart-icon-view {
  background-color: #fff;
}
.product-card .add-to-cart-icon-view:hover {
  box-shadow: 0px 0px 20px black;
  background-color: #fff;
  transform: scale(1.05);
  cursor: pointer;
}
.product-card .add-to-cart-icon-view .add-to-cart-icon {
  color: #4b4b4b;
  font-size: 20px;
  transition: 0.4s;
}
.product-card .add-to-cart-icon-view .add-to-cart-icon.filled {
  color: #019291;
}
.product-card .add-to-cart-icon-view:hover .add-to-cart-icon {
  color: #019291;
}
.product-card .product-card-image-div {
  width: 198px;
  height: 198px;
  max-width: 198px;
  overflow: hidden;
}
.product-card .product-card-image {
  width: 198px;
  height: 198px;
  object-fit: cover;
  transition: 0.8s;
}
.product-card:hover .product-card-image {
  transform: scale(1.2);
}
.product-card .product-card-footer {
  padding: 8px;
}
.product-card p {
  margin: 0;
  text-decoration: none;
}
.product-card .product-card-footer .product-card-name {
  font-size: 16px;
  text-transform: capitalize;
}

@media (max-width: 992px) {
  .product-card {
    margin: 10px;
  }
}
