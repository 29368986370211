.product {
  margin-bottom: 10vh;
}

/* Product Details Grid */
.details-grid {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detail-row {
  display: flex;
  align-items: stretch; /* Ensures both title and value align in height */
  gap: 10px;
}

.detail-title {
  flex: 1; /* Ensures titles and values take up equal space */
  font-weight: bold;
  text-align: left;
  background-color: #f8f9fa;
  padding: 10px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
}

.detail-value {
  flex: 2;
  text-align: left;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
}

.product .product-image {
  height: 600px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgb(203, 203, 203);
  background: radial-gradient(#f3f3f3, #2b2b2be7);
  position: relative;
}
.product .product-main-image {
  height: 440px;
  width: 80%;
  object-fit: contain;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 10px rgba(203, 203, 203, 0.683));
}
.product .product-images {
  overflow-x: scroll;
  scrollbar-width: none; /* Disable scrollbar width */
  -ms-overflow-style: none; /* IE and Edge */
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0; */

  width: 100%;
}
.product .product-images::-webkit-scrollbar {
  display: none;
}
.product .product-images img {
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  max-width: 100px;
  max-height: 100px;
  border-radius: 10px;
  object-fit: cover;
  cursor: pointer;
  transition: 0.4s;
  margin-left: 10px;
  margin-right: 10px;
}
.product .product-images img:hover {
  box-shadow: 0px 0px 10px gray;
  transform: scale(1.05);
}

.product .add-to-cart-btn {
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
  color: white;
  background-color: #019093;
  height: 40px;
  font-size: 14px;
  /* width: auto; */
  padding: 10px;
  border-radius: 10px;
  transition: 0.4s;
  border: none;
}
.product .add-to-cart-btn:hover {
  background-color: #000;
}
.product .quote-a-btn {
  color: white;
  background-color: #da0000;
  text-decoration: none;
  height: auto;
  font-size: 14px;
  width: 100px;
  padding: 10px;
  border-radius: 10px;
  transition: 0.4s;
}

.product .quote-a-btn:hover {
  background-color: #a3a3a3;
  color: black;
}

.product > .section-subheader {
  margin-top: 5vh;
  margin-bottom: 0;
}

.product .quote-div {
  margin-top: 3vh;
}
.product .quote-div label {
  font-weight: bold;
}
.product .quote-div input {
  width: 100px;
  height: 40px;
  margin-right: 10px;
  outline: none;
  text-align: center;
  border: solid 1px #d3d3d3;
  border-radius: 5px;
}

.product .product-details {
  height: 60vh;
  min-height: 40vh;
  padding-left: 20px;
}
.product .checkout-btn {
  text-decoration: none;
  color: white;
  background-color: #000;
  transition: 0.4s;
  width: 100% !important;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 50px;
  border-radius: 10px;
  box-shadow: 0px 10px 15px #d3d3d3;
  text-decoration: none;
  margin-top: 4vh;
}
.product .checkout-btn:hover {
  background-color: #019291;
  transform: translateY(-5%);
  box-shadow: 0px 10px 15px #3b3b3b;
}

@media (max-width: 1189px) {
  .product .product-image img {
    width: 400px;
    height: 40vh;
  }
}
@media (max-width: 992px) {
  .product .product-image {
    margin-bottom: 3vh;
    height: auto;
    object-fit: cover;
  }
  .product .product-main-image {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .product .product-images {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .product .product-details {
    height: auto;
  }
}

@media (max-width: 512px) {
  .product .product-main-image {
    max-width: 280px;
  }
}
@media (max-width: 320px) {
  .product .product-main-image {
    max-width: 200px;
  }
}
